import {useQuery} from '@tanstack/react-query';
import {WorkstreamService} from 'client';
import {getService} from 'utilities';

export const useWorkstream = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstream'],
    queryFn: getService(WorkstreamService).getAllApiV1WorkstreamAllGet,
  });

  return {data, isLoading};
};
