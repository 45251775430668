import {Employee, Goal, GoalRead, KeyResult, Team} from 'client';

export const isNumeric = (str: string) => {
  return !isNaN(parseFloat(str)) && isFinite(Number(str));
};

export const isSuperUser = (email?: string | null) => {
  return [
    'youcef@dropbox.com',
    'baochenh@dropbox.com',
    'swhitton@dropbox.com',
    'kshay@dropbox.com',
    'mtruong@dropbox.com',
    'lila@dropbox.com',
    'dbxta-pw0d2@dbx51.com',
  ].includes(email ?? '');
};

export const isCompanyGoalAdmin = (email?: string | null) =>
  ['alyce@dropbox.com', 'kaitlinshawhan@dropbox.com'].includes(email ?? '') || isSuperUser(email);

export const isContentAdmin = (email?: string) => {
  return (
    ['neilmcmahon@dropbox.com', 'robh@dropbox.com', 'smagier@dropbox.com'].includes(email ?? '') ||
    isSuperUser(email)
  );
};

export const isContentEditor = (email?: string) => {
  return (
    [
      'enaumann@dropbox.com',
      'mckennah@dropbox.com',
      'mjulian@dropbox.com',
      'lizall@dropbox.com',
      'littlej@dropbox.com',
      'kellyc@dropbox.com',
      'hsinha@dropbox.com',
      'afisher@dropbox.com',
      'lorin@dropbox.com',
    ].includes(email ?? '') || isContentAdmin(email)
  );
};

// One way sha-256 hash of email address to be used as a unique identifier
export const hashEmail = async (email: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const isGoal = (selected: Goal | KeyResult): selected is Goal => {
  return (selected as Goal).key_results !== undefined;
};

export const isGoalType = (selected: Goal | KeyResult | GoalRead): selected is Goal | GoalRead => {
  return (
    (selected as Goal).key_results !== undefined || (selected as GoalRead).key_results !== undefined
  );
};

// Sorts employees by level, tenure_percentile, and name
export const sortEmployees = (a: Employee, b: Employee) => {
  // Move employees with no level to the end
  if (!a.level && b.level) return 1;
  if (a.level && !b.level) return -1;
  if (!a.level && !b.level) return 0;

  // Extract prefix (e.g. "M" or "IC") and numeric part from the level
  const [prefixA = '', numA] = a.level?.match(/[A-Za-z]+|[\d]+/g) || [];
  const [prefixB = '', numB] = b.level?.match(/[A-Za-z]+|[\d]+/g) || [];

  // Parse numeric parts for comparison
  const levelANumber = parseInt(numA || '0', 10);
  const levelBNumber = parseInt(numB || '0', 10);

  // Sort by level number (higher is better)
  if (levelANumber !== levelBNumber) {
    return levelBNumber - levelANumber; // Higher levels come first
  }

  // If level numbers are the same, prioritize 'M' before 'IC'
  const prefixOrder = {M: 0, IC: 1};
  const orderA =
    prefixOrder[prefixA as keyof typeof prefixOrder] !== undefined
      ? prefixOrder[prefixA as keyof typeof prefixOrder]
      : 2;
  const orderB =
    prefixOrder[prefixB as keyof typeof prefixOrder] !== undefined
      ? prefixOrder[prefixB as keyof typeof prefixOrder]
      : 2;

  if (orderA !== orderB) {
    return orderA - orderB; // Managers (M) should go before ICs if level numbers are equal
  }

  // Sort by tenure (higher is better)
  const tenureA = a.tenure_percentile || 0;
  const tenureB = b.tenure_percentile || 0;

  if (tenureA !== tenureB) {
    return tenureB - tenureA; // Higher tenure first
  }

  // If hire dates are equal or either is missing, sort by name
  return a.name.localeCompare(b.name);
};

// Sorts team by name
export const sortTeams = (a: Team, b: Team) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

export const sortEmployeesSelfFirst = (employee: Employee) => (a: Employee, b: Employee) => {
  return a.ldap === employee.ldap ? -1 : b.ldap === employee.ldap ? 1 : sortEmployees(a, b);
};

export const isGoalPrivate = (goal: Goal): boolean => {
  return !!goal.private || !!goal.is_custom_privacy_included;
};
